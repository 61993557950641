//================================================================
//  Page: Chat for Lendlease AI
//================================================================

//  Purpose: Home page for the Lendlease AI portal

//  Example:
//    <Chat></Chat>    

//================================================================


//Libraries
import React, { useState, useEffect, useContext, useReducer } from 'react';
import Markdown from 'react-markdown';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import HistoryCard from './Components/HistoryCard';

//Functions
import queryListener from '../../Library/QueryListener';
import writeDocument from '../../Library/WriteDocument';
import uploadFile from '../../Library/UploadFile';

//Images
import binDisabled from '../../Components/Images/Icon_Bin_Grey.svg';
import binEnabled from '../../Components/Images/Icon_Bin_Green.svg';
import chatloadingIcon from '../../Components/Images/Icon_Chat_Loading.gif';
import fileUploadingIcon from '../../Components/Images/Icon_Loading_Green.svg'
import fileIcon from '../../Components/Images/Icon_File_White.svg'
import fileTick from '../../Components/Images/Icon_Tick_Black.svg'
import fileError from '../../Components/Images/Icon_Error_Red.svg'
import chatbotAvatar from '../../Components/Images/Icon_Chatbot_Avatar_Green.svg';
import fileEnabled from '../../Components/Images/Icon_File_Enabled.svg';
import fileDisabled from '../../Components/Images/Icon_File_Disabled.svg';
import sendMessageIcon from '../../Components/Images/Icon_Send_Message.svg';


//CSS
import './Chat.css';


export default function Chat() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      // Thread States
      'threadid': '', // unique id for the current thread
      'threadtype': 'new', // 'new' or 'existing'
      'selectedThreads': [], // Holds the threads that will be deleted
      'displayname': '', // TBD gotta talk with Ellycia

      //Question & File input states
      'question': '',
      'preventSubmit': false,
      'questionError': '',
      'fileStatus': 'onload' // 'onload' or 'pending'
    }
  )

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Holds all the active and inactive chat threads
  const [threads, setThreads] = useState([]);

  // Holds all the chats for the current active thread
  const [chats, setChats] = useState([]);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Saves the current thread in Firestore
  function saveThread() {

    if (chats.length === 0) return;
    if (chats[0]?.question === undefined) return;

    // =====================================
    //  Create a thread in firestore
    // =====================================

    const document = {
      'threadid': formData.threadid,
      'displayname': `${chats[0]?.question.length > 40 ? `${chats[0]?.question.substr(0, 40)}...` : chats[0]?.question}`,
      'emailaddress': getUser.emailaddress,
      'description': chats[0]?.answer === '' ? chats[0]?.question : chats[0]?.answer,
      'created': new Date(),
      'status': 'active',
    };

    writeDocument('threads', formData.threadid, document, false).then(() => {

      setFormData({
        'threadtype': 'existing'
      });

    });

  };

  // Sends chat request to firestore
  function deleteThread() {

    // =====================================
    //  Flag each thread in firestore inactive
    // =====================================

    const promises = [];
    const currentThread = chats[0]?.threadid;

    formData.selectedThreads.forEach((threadid) => {

      if (currentThread === threadid) {

        const welcomeMessage = [
          {
            'documentid': undefined,
            'threadid': '',
            'emailaddress': getUser.emailaddress,
            'answer': `Welcome to Lendlease AI.
          \nAsk questions through the below prompt. All generated content is secured to your account, and can be deleted at anytime.
          \nThanks for stopping by and enjoy :-)`,
            'answerdate': new Date(),
            'filename': '',
            'status': 'complete-welcome',
          }
        ];

        setChats(welcomeMessage);

      }

      const document = {
        'status': 'inactive',
      };

      promises.push(
        writeDocument('threads', threadid, document, true),
      );

    });

    Promise.all(promises).then(() => {

      setFormData({ 'selectedThreads': [] });

    }).catch((error) => {

      console.log('error', error);

    });

  };

  // Send chat on 'Enter' key press
  function keyHandler(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      chatHandler();
    }
  }


  // Sends chat request to firestore
  function chatHandler() {

    // =====================================
    //  Input Validation Checks
    // =====================================

    // 1. Check if submit is disabled
    if (formData.preventSubmit === true) {
      return;

      // 2. Single key inputs --> enter, etc
    } else
     {

      setFormData({
        'questionError': '',
        'preventSubmit': true,
      });

    }

    // =====================================
    //  Write question to firestore
    // =====================================

    const documentId = `${getUser.emailaddress}-${Date.now()}`;
    const document = {
      'documentid': documentId,
      'threadid': formData.threadid,
      'emailaddress': getUser.emailaddress,
      'question': formData.question,
      'questiondate': new Date(),
      'answer': '',
      'answerdate': '',
      'filename': '',
      'filesize': 0,
      'status': 'pending',
    };

    writeDocument('chats', documentId, document, false).then(() => {

      setFormData({
        'question': '',
        'preventSubmit': false,
      });

    }).catch((error) => {

      console.log('error', error);
      setFormData({
        'question': '',
        'questionError': 'Unknown error occured, try again later.',
      });

    });

  };

  // Upload PDF/CSV file
  function uploadPDFCSV(file) {

    // Ignore blank files
    if (file === undefined) return;

    // Create a new document id
    const documentId = `${getUser.emailaddress}-${Date.now()}`;

    // =====================================
    // Check if the content is a PDF or CSV
    // =====================================

    if (file.type !== 'application/pdf' && file.type !== 'text/csv' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

      const document = {
        'documentid': documentId,
        'threadid': formData.threadid,
        'emailaddress': getUser.emailaddress,
        'question': '',
        'questiondate': new Date(),
        'answer': 'I only support PDF & CSV files. Please try another file.',
        'answerdate': '',
        'filename': file.name,
        'filesize': file.size,
        'status': 'file-error',
      };

      // Return an error message to the chat thread
      return writeDocument('chats', documentId, document, false).then(() => {

        setFormData({
          'preventSubmit': false,
        });

      }).catch((error) => {

        console.log('error', error);
        setFormData({
          'uploadStatus': 'error-type',
          'questionError': 'Something went wrong',
        });

      });

    }

    // =====================================
    // Check if the content over our size limits
    // =====================================

    if ((file?.size / 1024 / 1024) > 20) {

      const document = {
        'documentid': documentId,
        'threadid': formData.threadid,
        'emailaddress': getUser.emailaddress,
        'question': '',
        'questiondate': new Date(),
        'answer': 'I only support PDF & CSV files up to 20 MB',
        'answerdate': '',
        'filename': file.name,
        'filesize': file.size,
        'status': 'file-error',
      };

      // Return an error message to the chat thread
      return writeDocument('chats', documentId, document, false).then(() => {

        setFormData({
          'preventSubmit': false,
        });

      }).catch((error) => {

        console.log('error', error);
        setFormData({
          'uploadStatus': 'error-type',
          'questionError': 'Something went wrong',
        });

      });

    }

    // =====================================
    // Pasted checks, proceed with upload
    // =====================================

    // Disable inputs
    setFormData({
      'questionError': '',
      'preventSubmit': true,
    });

    const document = {
      'documentid': documentId,
      'threadid': formData.threadid,
      'emailaddress': getUser.emailaddress,
      'question': '',
      'questiondate': new Date(),
      'answer': '',
      'answerdate': '',
      'filename': file.name,
      'filesize': file.size,
      'status': 'pending-file',
    };

    // Store document in firestore
    writeDocument('chats', documentId, document, false).then(() => {

      let filepath = '';

      if (file.type === 'application/pdf') {

        filepath = `uploads/${getUser.emailaddress}/chat/${documentId}.pdf`;

      } else if (file.type === 'text/csv') {

        filepath = `uploads/${getUser.emailaddress}/chat/${documentId}.csv`;

      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

        filepath = `uploads/${getUser.emailaddress}/chat/${documentId}.xlsx`;

      }

      // Upload file: success
      return uploadFile(filepath, file).then(() => {

        setFormData({
          'preventSubmit': false,
        });

      });

      // Upload file: fail
    }).catch((error) => {

      console.log('error', error);
      setFormData({
        'uploadStatus': 'error-type',
        'fileError': 'Something went wrong',
      });

    });

  };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Get all threads
  useEffect(() => {

    // Conditions
    if (getUser.emailaddress === undefined) return;

    function onLoadChange(documents) {

      // =====================================
      //  No Threads, return the welcome message
      // =====================================

      if (documents.length === 0 && chats.length === 0) {

        const welcomeMessage = [
          {
            'documentid': undefined,
            'threadid': '',
            'emailaddress': getUser.emailaddress,
            'answer': `Welcome to Lendlease AI.
          \nAsk questions through the below prompt. All generated content is secured to your account, and can be deleted at anytime.
          \nThanks for stopping by and enjoy :-)`,
            'answerdate': new Date(),
            'filename': '',
            'status': 'complete',
          }
        ];

        setChats(welcomeMessage);

        setFormData({
          'questionError': 'Type something...',
          'threadid': `${getUser.emailaddress}-${Date.now()}`,
          'threadtype': 'new',
        });

        setPageStatus('onload');

      } else {

        // =====================================
        //  Found existing Thread
        // =====================================

        setThreads(documents.reverse());

        if (formData.threadid !== '') return; // DO NOT change from the current active thread

        setFormData({
          'threadid': documents[0].threadid,
          'threadtype': 'existing',
        });

        setPageStatus('onload');

      }

    }

    function onError(error) {

      console.log('error', error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = queryListener('threads', [
      ['emailaddress', '==', getUser.emailaddress],
      ['status', '==', 'active'],
    ], onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser]);

  // Get all Chats for the current 'threadid'
  useEffect(() => {

    // Conditions
    if (getUser.emailaddress === undefined) return;
    if (formData.threadid === '') return;

    function onLoadChange(documents) {

      if (documents.length === 0) return;

      // Reverse order of docs, so the thread has newest to the bottom.
      setChats(documents.reverse());

    }

    function onError(error) {

      console.log('error', error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = queryListener('chats', [
      ['emailaddress', '==', getUser.emailaddress],
      ['threadid', '==', formData.threadid],
    ], onLoadChange, onLoadChange, onError);

    setFormData({ 'unsubscribe': unsubscribe });

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.threadid]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      requiredRoles={['isUser']}
      requiredRolesValue={true}
      status={pageStatus}
      body={
        <div className='Chat-Section-Container'>

          {/* ============================== */}
          {/*         LEFT PANE              */}
          {/* ============================== */}

          <div className='Chat-Section-Left'>

            <div className='flex flex-col-reverse overflow-x-auto'>

              {
                chats?.map((chat, index) => (

                  <div className='flex flex-col p-2' key={index}>

                    {/* ============================================= */}
                    {/*             Question asked by User            */}
                    {/* ============================================= */}

                    <div className='flex flex-row-reverse'>
                      {

                        // Normal chats
                        chat.status === 'pending' || chat.status === 'complete' ? 
                        (

                          chat.question !== undefined && (
                            <span className='Chat-Question'>
                              {chat.question}
                            </span>
                          )

                        ) 
                        :

                        // Pending File
                        chat.status === 'pending-file' ? 
                        (

                          <span className='Chat-Question flex flex-row'>
                            <img className='pr-[10px]' alt='file-loading' src={fileIcon}></img>
                            <label className=''>{chat.filename}</label>
                            <label className='ml-[10px]'>{new Intl.NumberFormat()?.format(chat?.filesize / 1024 / 1024)} MB</label>
                            <img className='ml-[5px] animate-spin' alt='file-loading' src={fileUploadingIcon}></img>
                          </span>

                        ) 
                        :

                        // Error File
                        chat.status === 'file-error' ? (

                          <span className='Chat-Question flex flex-row'>
                            <img className='pr-[10px]' alt='file-loading' src={fileIcon}></img>
                            <label className=''>{chat.filename}</label>
                            <label className='ml-[10px]'>{new Intl.NumberFormat()?.format(chat?.filesize / 1024 / 1024)}</label>
                            <label className='ml-[10px]'>MB</label>
                            <img className='ml-[5px]' alt='file-error' src={fileError}></img>
                          </span>

                        ) 
                        :

                        // Complete File
                        chat.status === 'complete-file' ? 
                        (

                          <span className='Chat-Question flex flex-row'>
                            <img className='pr-[10px]' alt='file-loading' src={fileIcon}></img>
                            <label className=''>{chat.filename}</label>
                            <label className='ml-[10px]'>{new Intl.NumberFormat()?.format(chat?.filesize / 1024 / 1024)}</label>
                            <label className='ml-[10px]'>MB</label>
                            <img className='ml-[5px]' alt='file-done' src={fileTick}></img>
                          </span>

                        ) 
                        :
                        // Unknown status from the backend ~ Just hide the chat!
                        null

                      }
                    </div>

                    {/* ============================================= */}
                    {/*             Answer provided by AI             */}
                    {/* ============================================= */}

                    <div className=' flex flex-row'>
                      {

                        // Pending status for a chat
                        chat.status === 'pending' ? 
                        (
                          <img className='ml-[50px] w-[50px] mb-[10px]' alt='chat-loading' src={chatloadingIcon}></img>
                        ) 
                        :

                        // Pending status for file been uploaded
                        chat.status === 'pending-file' ? null

                        // Successful chat (text)
                        : chat.status === 'complete' || chat.status === 'complete-welcome' || chat.status === 'file-error' ? (

                          <div className='flex flex-row h-fit'>
                            <img className=' self-start' src={chatbotAvatar} alt='Chatbot-Avatar'></img>
                            <Markdown className='Chat-Answer'>{chat.answer}</Markdown>
                          </div>

                        )

                        // Successful chat (file)
                        : chat.status === 'complete-file' ? 
                        (

                          <span className='flex flex-row'>
                            <img className=' self-start' src={chatbotAvatar} alt='Chatbot-Avatar'></img>

                            <div className='Chat-Answer flex flex-row'>
                              <label>Thanks for uploading,</label>
                              <img className='pr-[5px] ml-[5px]' alt='file-loading' src={fileIcon}></img>
                              <label>{chat.filename}</label>
                            </div>

                          </span>


                        ) 
                        :
                        // Unknown status from the backend ~ Just hide the chat!
                          null

                      }
                    </div>

                  </div>

                ))

              }

            </div>

            {/* ------------------------------------------------------ */}
            {/*  Chat Input                                            */}
            {/* ------------------------------------------------------ */}

            <div className='flex flex-row gap-2 h-[70px] lg:h-[130px]'>

              {/* File Upload Icon */}
              <label className='h-[42px] self-center' htmlFor='file-upload'>
                {
                  formData.preventSubmit === true ? (

                    // Disabled icon upload image
                    <img className='w-[42px] mr-[10px] cursor-not-allowed' src={fileDisabled} alt='File-Disabled'></img>

                  ) : (

                    // Enabled image
                    <img className='w-[42px] mr-[10px] cursor-pointer hover:scale-105' src={fileEnabled} alt='File-Enabled'></img>

                  )
                }

              </label>
              <input disabled={formData.preventSubmit} onChange={(e) => uploadPDFCSV(e?.target?.files[0])} hidden id='file-upload' type='file' accept='application/pdf, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'></input>

              {/* Chat input box */}
              <textarea
                className='Chat-Input'
                placeholder={formData.questionError}
                value={formData.question}
                onChange={(e) => setFormData({ 'question': e.target.value })}
                onKeyDown={(e) => keyHandler(e)}
              ></textarea>

              {/* Send Message Icon */}

              {
                formData.question.trim() === '' ? 
                (

                  // Disabled icon upload image
                  <img className='w-[42px] mr-[10px] cursor-not-allowed' src={sendMessageIcon} alt='Send-Disabled'></img>

                ) 
                : 
                (

                  // Enabled image
                  <img onClick={chatHandler} className='w-[42px] mr-[10px] cursor-pointer hover:scale-105' src={sendMessageIcon} alt='Send-Enabled'></img>

                )
              }



            </div>

          </div>

          {/* ============================== */}
          {/*         RIGHT PANE             */}
          {/* ============================== */}

          <div className='Chat-Section-Right'>

            {/* ========================= */}
            {/*      Top Section          */}
            {/*   - Delete Threads        */}
            {/*   - Conversation Threads  */}
            {/* ========================= */}
            <div>

              {/* Delete Threads */}
              <div className='mb-[20px] flex flex-row justify-between p-1'>

                <h5 className='mb-[20px] align-middle'>Conversation History {threads.length === 0 ? null : `(${threads.length})`} </h5>

                {
                  formData.selectedThreads.length === 0 ? (

                    <img className='self-start w-[28px] cursor-not-allowed' src={binDisabled} alt='Bin-Disabled'></img>

                  ) : (

                    <img className='self-start w-[28px] cursor-pointer hover:scale-105' src={binEnabled} alt='Bin-Enabled' onClick={() => deleteThread()}></img>

                  )

                }

              </div>

              {/* Conversation Threads */}
              <div className='Chat-Section-Right-Threads overflow-x-auto h-[calc(100vh-320px)] md:max-h-[calc(100vh-370px)]'>

                {
                  threads.length === 0 ? (

                    <p className='text-center font-light'>
                      Start a new conversation with Lendlease AI with the chat box on the left. The <strong>Save Conversation</strong> button will save your current conversation in the <strong>Conversation History</strong>, whilst creating a new conversation with Lendlease AI.
                    </p>

                  ) : (

                    // Show each thread in a card
                    threads.map((object, index) => (

                      <HistoryCard
                        index={index}
                        formData={formData}
                        setFormData={setFormData}
                        threadid={object?.threadid}
                        displayname={object?.displayname}
                        description={object?.description}
                        created={object?.created}
                      ></HistoryCard>

                    ))

                  )

                }

              </div>

            </div>

            {/* =========================================== */}
            {/*      Bottom Section                         */}
            {/*   - Save Conversation                       */}
            {/*   - Reset and start a new conversation      */}
            {/* =========================================== */}

            {/* Create Thread */}
            {

              formData.threadtype === 'existing' ? (

                // Reset and start a new conversation
                <button className='Primary-Button' onClick={() => {

                  formData.unsubscribe();

                  setFormData({
                    'threadid': `${getUser.emailaddress}-${Date.now()}`,
                    'threadtype': 'new',
                  });

                  const welcomeMessage = [
                    {
                      'documentid': undefined,
                      'threadid': '',
                      'emailaddress': getUser.emailaddress,
                      'answer': `Welcome to Lendlease AI.
                      \nAsk questions through the below prompt. All generated content is secured to your account, and can be deleted at anytime.
                      \nThanks for stopping by and enjoy :-)`,
                      'answerdate': new Date(),
                      'filename': '',
                      'status': 'complete-welcome',
                    }
                  ];

                  setChats(welcomeMessage);

                }}>Reset and Start New Conversation</button>

              ) : (

                // Save Conversation
                <button disabled={(chats[0]?.status === 'complete-welcome' || chats[chats.length - 1]?.status === 'pending' || chats[chats.length - 1]?.status === 'pending-file') ? true : false} className='Primary-Button' onClick={() => saveThread()}>Save Conversation</button>

              )

            }

          </div>

        </div>
      }
    ></PageComponent>
  )
}
