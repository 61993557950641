//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for Events App

//  Example:
//    <Navbar></Navbar>


//================================================================


//Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';

// Contexts

//Components
import Logout from '../../Library/Logout';

//Images
import LLLogo from '../Images/Icon_LLLogo_Green.png';

//CSS
import './Navbar.css';


export default function Navbar() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------


  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div>

        {/* ---------------------------------------------------- */}
        {/*  Nav Header                                          */}
        {/* ---------------------------------------------------- */}

        <div className='w-full flex flex-row justify-between items-center bg-[#141C25] text-[white] px-[10px] py-0 h-[70px] drop-shadow-xl'>
    
          {/* Logo */}
          <div className='flex items-center ml-[25px]'>

            <NavLink to='/'>
              <img className='mr-[20px] cursor-pointer' src={LLLogo} alt='Lendlease Logo' width='55px' height='39px'></img>
            </NavLink>

            <h5 className='font-medium m-0 whitespace-nowrap overflow-hidden overflow-ellipsis'> Lendlease AI</h5>

          </div>

          <Logout></Logout>

        </div>
        

      </div>
    )

}
