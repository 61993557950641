//================================================================
//  Component: Footer
//================================================================

//  Purpose: This is the page footer, which includes the LL privacy policy link

//  Example:
//    <Footer></Footer>    

//================================================================

//Libraries
import React from 'react';


export default function Footer() {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='flex flex-col justify-between items-center relative bottom-0 w-full h-auto py-3 px-4 bg-[#141C25] text-white text-[15px] xl:flex-row xl:h-[60px]'>
      <a className='text-white no-underline hover:underline mb-0' href='https://www.lendlease.com/au/privacy/' target='_blank' rel='noopener noreferrer'>
        Privacy Policy
      </a>

      <div className='flex flex-row gap-2 text-center'>
        © Lendlease Corporation, all rights reserved.
      </div>
    </div>

  )
}
